dmx.BaseComponent = dmx.createClass({

    constructor: function(node, parent) {
        this.$node = node;
        this.parent = parent;
        this.bindings = {};
        this.propBindings = {};
        this.children = [];
        this.listeners = {};
        this.props = {};
        this.data = {};
        this.seed = Math.random();

        this.name = node.getAttribute('id') || node.getAttribute('name') || this.type.toLowerCase().replace(/^dmx-/, '');
        this.name = this.name.replace(/[^\w]/g, '');
        this.dmxDomId = node.getAttribute('dmxDomId');

        try {
            this.$parseAttributes(node);
            this.$initialData();
            this.render(node);
            if (this.beforeMount(node) !== false) {
                this.$mount(node);
                if (this.$node) {
                    //this.$customAttributes('mount', this.$node);
                    this.$customAttributes('mounted', this.$node);
                    if (this.dmxDomId) {
                        // Restore dmxDomId for Wappler
                        this.$node.setAttribute('dmxDomId', this.dmxDomId);
                    }
                    this.$node.dmxComponent = this;
                    this.$node.dmxRendered = true;
                }
                this.dispatchEvent('mount');
                this.mounted();
            }
        } catch (e) {
            console.error(e);
        }
    },

    tag: null,
    initialData: {},
    attributes: {},
    methods: {},
    events: {
        mount: Event,
        destroy: Event
    },

    render: function(node) {
        if (this.$node) {
            this.$parse();
        }
    },

    // find component based on name inside children
    find: function(name) {
        if (this.name == name) return this;

        for (var i = 0; i < this.children.length; i++) {
            var found = this.children[i].find(name);
            if (found) return found;
        }

        return null;
    },

    // internal method for Wappler
    __find: function(dmxDomId) {
        if (this.dmxDomId == dmxDomId) return this;

        for (var i = 0; i < this.children.length; i++) {
            var found = this.children[i].__find(dmxDomId);
            if (found) return found;
        }

        return null;
    },

    // internal method for Wappler
    __replace: function(dmxDomId) {
        var child = this.__find(dmxDomId);

        if (child) {
            child.$destroy();

            var node = document.querySelector('[dmxDomId="' + dmxDomId + '"]');
            if (node) {
                var index = child.parent.children.indexOf(child);
                var Component = dmx.__components[child.data.$type];

                if (index > -1 && Component) {
                    var component = new Component(node, child.parent);
                    child.parent.children.splice(index, 1, component);
                    if (component.name) {
                        child.parent.add(component.name, component.data);
                    }
                }
            }

            dmx.requestUpdate();
        }
    },

    // internal method for Wappler
    __remove: function(dmxDomId) {
        var child = this.__find(dmxDomId);

        if (child) {
            child.$destroy();

            var index = child.parent.children.indexOf(this);
            if (index > -1) {
                child.parent.children.splice(index, 1);
            }

            dmx.requestUpdate();
        }
    },

    beforeMount: dmx.noop,
    mounted: dmx.noop,

    beforeUpdate: dmx.noop,
    update: dmx.noop,
    updated: dmx.noop,

    beforeDestroy: dmx.noop,
    destroyed: dmx.noop,

    addEventListener: function(type, callback) {
        if (!(type in this.listeners)) {
            this.listeners[type] = new Set();
        }
        this.listeners[type].add(callback);
    },

    removeEventListener: function(type, callback) {
        if (!(type in this.listeners)) return;
        this.listeners[type].delete(callback);
    },

    dispatchEvent: function(event, props, data, nsp) {
        if (typeof event == 'string') {
            try {
                var ComponentEvent = this.events[event];
                event = new ComponentEvent(event, props);
            } catch (err) {
                var eventType = event;
                event = document.createEvent('CustomEvent');
                event.initEvent(eventType, props && props.bubbles, props && props.cancelable);
                if (!(event instanceof Event)) {
                    console.warn('Unknown event ' + event, this.events);
                    return false;
                }
                var preventDefault = event.preventDefault;
                event.preventDefault = function() {
                    preventDefault.call(this);
                    try {
                        Object.defineProperty(this, 'defaultPrevented', {
                            get: function () {
                                return true;
                            }
                        });
                    } catch(e) {
                        this.defaultPrevented = true;
                    }
                    return event;
                }
            }
        }

        if (!(event.type in this.listeners)) return true;

        event.nsp = nsp;
        event.target = this;
        event.$data = data || {};
        for (let listener of this.listeners[event.type]) {
            if (listener.call(this, event) === false) {
                event.preventDefault();
            }
        }

        return !event.defaultPrevented;
    },

    $addChild: function(name, node) {
        var Component = dmx.__components[name];
        var component = new Component(node, this);
        this.children.push(component);
        if (component.name) {
            if (this.data[component.name] && dmx.debug) {
                console.warn('Duplicate name "' + component.name + '" found, component not added to scope.');
                //return;
            }
            this.set(component.name, component.data);
        }
    },

    $customAttributes: function(hook, node) {
        dmx.dom.getAttributes(node).forEach(function(attr) {
            if (dmx.__attributes[hook][attr.name]) {
                node.removeAttribute(attr.fullName);
                dmx.__attributes[hook][attr.name].call(this, node, attr);
            }
        }, this);
    },

    $parse: function(node) {
        node = node || this.$node;

        if (!node) return;

        if (node.nodeType === 3) {
            if (dmx.reExpression.test(node.nodeValue)) {
                var nodeValue = node.nodeValue;

                if (nodeValue.substr(0, 2) == '{{' && nodeValue.substr(-2) == '}}' && nodeValue.indexOf('{{', 2) == -1) {
                    nodeValue = nodeValue.substring(2, nodeValue.length - 2);
                }

                /*if (nodeValue.substr(0, 2) == '{{' && nodeValue.substr(-2) == '}}' && nodeValue.indexOf('{{', 2) == -1) {
                    nodeValue = nodeValue.substring(2, nodeValue.length - 2);
                } else {
                    var fragment = new DocumentFragment();
                    var parts = nodeValue.split(/\{\{|\}\}/);

                    parts.forEach(function(part, i) {
                        var textNode = document.createTextNode('');
                        fragment.appendChild(textNode);

                        if (i % 2 == 0) {
                            textNode.nodeValue = part;
                        } else {
                            this.$addBinding(part, function(value, oldValue) {
                                textNode.nodeValue = value;
                            });
                        }
                    }, this);

                    node.parentNode.replaceChild(fragment, node);

                    return;
                }*/

                this.$addBinding(nodeValue, function(value, oldValue) {
                    node.nodeValue = value;
                });
            }

            return;
        }

        if (node.nodeType !== 1) return;

        if (dmx.config.mapping) {
            Object.keys(dmx.config.mapping).forEach(function(map) {
                dmx.array(node.querySelectorAll(map)).forEach(function(node) {
                    if (!node.hasAttribute('is')) {
                        node.setAttribute('is', 'dmx-' + dmx.config.mapping[map]);
                    }
                });
            });
        }

        dmx.dom.walk(node, function(node) {
            if (node == this.$node) {
                // skip current node
                return;
            }

            // Element Node
            if (node.nodeType === 1) {
                var tagName = node.tagName.toLowerCase();
                var attributes = dmx.dom.getAttributes(node);

                if (node.hasAttribute('is')) {
                    tagName = node.getAttribute('is');
                }

                if (dmx.reIgnoreElement.test(tagName)) {
                    // ignore element
                    return false;
                }

                this.$customAttributes('before', node);
                var idx = attributes.findIndex(function(attr) { return attr.name === 'repeat'; });
                if (idx !== -1) return false;

                if (dmx.rePrefixed.test(tagName)) {
                    tagName = tagName.replace(/^dmx-/i, '');

                    if (tagName in dmx.__components) {
                        node.isComponent = true;
                        if (!node.dmxRendered) {
                          this.$addChild(tagName, node);
                        } else if (window.__WAPPLER__) {
                            // This breaks some components in design view
                            // causes flows to trigger constantly
                            // components ofter have there own parsing and this breaks it
                            if (node.dmxComponent && node.dmxComponent.$parse) {
                                // for now ignode specific for flows with script tag
                                if (!dmx.reIgnoreElement.test(node.tagName)) {
                                    node.dmxComponent.$parse();
                                }
                            }
                        }
                        return false;
                    } else {
                        console.warn('Unknown component found! ' + tagName);
                        return;
                    }
                }

                //this.$customAttributes('mount', node);
                this.$customAttributes('mounted', node);
            }

            // Text Node
            if (node.nodeType === 3) {
                if (dmx.reExpression.test(node.nodeValue)) {
                    var nodeValue = node.nodeValue;

                    if (nodeValue.substr(0, 2) == '{{' && nodeValue.substr(-2) == '}}' && nodeValue.indexOf('{{', 2) == -1) {
                        nodeValue = nodeValue.substring(2, nodeValue.length - 2);
                    }
    
                    /*if (nodeValue.substr(0, 2) == '{{' && nodeValue.substr(-2) == '}}' && nodeValue.indexOf('{{', 2) == -1) {
                        nodeValue = nodeValue.substring(2, nodeValue.length - 2);
                    } else {
                        var fragment = new DocumentFragment();
                        var parts = nodeValue.split(/\{\{|\}\}/);

                        parts.forEach(function(part, i) {
                            var textNode = document.createTextNode('');
                            fragment.appendChild(textNode);

                            if (i % 2 == 0) {
                                textNode.nodeValue = part;
                            } else {
                                this.$addBinding(part, function(value, oldValue) {
                                    textNode.nodeValue = value;
                                });
                            }
                        }, this);

                        node.parentNode.replaceChild(fragment, node);

                        return;
                    }*/
                    this.$addBinding(nodeValue, function(value, oldValue) {
                        node.nodeValue = value;
                    });
                }
            }
        }, this);
    },

    $update: function(idents) {
        try {
            if (this.beforeUpdate(idents) !== false) {
                const props = dmx.clone(this.props);
                const didUpdate = this.$updateBindings(this.bindings, idents);
                const updatedProps = this.$updatePropBindings(idents);

                // TODO: until all components are update call update always
                //if (didUpdate || updatedProps.size || (idents && idents.has('*'))) {
                    try {
                        this.update(props, updatedProps);
                    } catch (e) {
                        console.error(e);
                    }
                //}

                this.children.forEach(function(child) {
                    child.$update(idents);
                });

                this.updated();
            }
        } catch (e) {
            console.error(e);
        }
    },

    $canSkip: function(binding, idents) {
        if (idents && !idents.has('*')) {
            for (const ident of binding.idents) {
                if (idents.has(ident)) {
                    return false;
                }
            }

            return true;
        }

        return false;
    },

    $updateBindings: function(bindings, idents) {
        let didUpdate = false;

        for (const expression in bindings) {
            if (bindings.hasOwnProperty(expression)) {
                const binding = bindings[expression];

                if (this.$canSkip(binding, idents)) continue;

                const value = dmx.parse(expression, this);

                if (!dmx.equal(value, binding.value)) {
                    for (const callback of binding.callbacks) {
                        callback.call(this, value, binding.value);
                    }

                    didUpdate = true;
                    binding.value = dmx.clone(value);
                }
            }
        }

        return didUpdate;
    },

    $updatePropBindings: function(idents) {
        const updated = new Set();

        for (const prop in this.propBindings) {
            if (this.propBindings.hasOwnProperty(prop)) {
                const binding = this.propBindings[prop];

                if (this.$canSkip(binding, idents)) continue;

                const value = dmx.parse(binding.expression, this);

                if (!dmx.equal(value, binding.value)) {
                    binding.callback.call(this, value);
                    binding.value = dmx.clone(value);
                    updated.add(prop);
                }
            }
        }

        return updated;
    },

    $parseAttributes: function(node) {
        var self = this;

        if (this.attributes) {
            Object.keys(this.attributes).forEach(function(prop) {
                var options = self.attributes[prop];
                var value = options.default;

                if (node.hasAttribute(prop)) {
                    if (options.type == Boolean) {
                        value = true;
                    } else {
                        value = node.getAttribute(prop);

                        if (options.type == Number) {
                            // Only set number is a valid number is given
                            if (value && !isNaN(Number(value))) {
                                value = Number(value);
                            }
                        }

                        if (options.type == String) {
                            value = String(value);
                        }

                        if (options.validate && !options.validate(value)) {
                            value = options.default;
                        }
                    }

                    node.removeAttribute(prop);
                }

                if (node.hasAttribute('dmx-bind:' + prop)) {
                    const expression = node.getAttribute('dmx-bind:' + prop);
                    const callback = this.$propBinding(prop).bind(this);

                    this.propBindings[prop] = {
                        expression: expression,
                        callback: callback,
                        idents: dmx.getIdents(expression),
                        value: null
                    };

                    node.removeAttribute('dmx-bind:' + prop);
                }

                self.props[prop] = dmx.clone(value);
            }, this);

            this.$updatePropBindings();
        }

        if (this.events) {
            Object.keys(this.events).forEach(function(event) {
                if (node.hasAttribute('on' + event)) {
                    //self.addEventListener(event, Function('event', node.getAttribute('on' + event)));
                    dmx.eventListener(self, event, Function('event', node.getAttribute('on' + event)), {});
                    node.removeAttribute('on' + event);
                }
            });
        }

        dmx.dom.getAttributes(node).forEach(function(attr) {
            if (attr.name == 'on' && this.events[attr.argument]) {
                dmx.eventListener(self, attr.argument, function(event) {
                    if (event.originalEvent) {
                        event = event.originalEvent;
                    }

                    var returnValue = dmx.parse(attr.value, dmx.DataScope({
                        $event: event.$data,
                        $originalEvent: event
                    }, self));

                    return returnValue;
                }, attr.modifiers);

                node.removeAttribute(attr.fullName);
            }
        }, this);
    },

    $propBinding: function(prop) {
        var options = this.attributes[prop];
        var self = this;

        return function(value) {
            if (value === undefined) {
                value = options.default;
            }

            if (options.type == Boolean) {
                value = !!value;
            }

            if (value != null) {
                if (options.type == Number) {
                    if (value !== '' && !isNaN(Number(value))) {
                        value = Number(value);
                    } else {
                        value = options.default;
                    }
                }

                if (options.type == String) {
                    value = String(value);
                }
            }

            if (options.validate && !options.validate(value)) {
                value = options.default;
            }

            self.props[prop] = dmx.clone(value);
        };
    },

    $initialData: function() {
        Object.assign(
            this.data,
            { $type: this.type },
            typeof this.initialData == 'function' ? this.initialData() : this.initialData
        );

        Object.keys(this.methods).forEach(function(method) {
            var self = this;
            this.data['__' + method] = function() {
                return self.methods[method].apply(self, Array.prototype.slice.call(arguments, 1));
            };
        }, this);
    },

    $mount: function(node) {
        if (this.$placeholder && this.$node) {
            dmx.dom.replace(this.$placeholder, this.$node);
        }
    },

    $addBinding: function(expression, cb) {
        this.bindings[expression] = this.bindings[expression] || {
            expression: expression,
            value: dmx.parse(expression, this),
            callbacks: [],
            idents: dmx.getIdents(expression)
        };
        this.bindings[expression].callbacks.push(cb);
        cb.call(this, this.bindings[expression].value);
    },

    $destroy: function() {
        this.dispatchEvent('destroy');
        this.beforeDestroy();
        this.$destroyChildren();
        if (this.parent) {
            this.parent.del(this.name);
        }
        if (this.$node) {
            dmx.dom.remove(this.$node);
        }
        this.destroyed();
    },

    $destroyChildren: function() {
        this.children.forEach(function(child) {
            child.$destroy();
        });

        this.children = [];
    },

    // some parent components can reference this component and expressions
    // referencing them should also be evaluated (repeat.items for example)
    updateRelated: function() {
        if (this.parent) {
            let parent = this.parent;
            while (parent) {
                if (parent.data && (parent.data.$type == 'repeat' || parent.data.$type == 'form-repeat')) {
                    dmx.requestUpdate(parent.name);
                }
                parent = parent.parent;
            }
        }
    },

    get: function(name, ignoreParents) {
        if (this.data.hasOwnProperty(name)) {
            return this.data[name];
        }

        if (this.parent && ignoreParents !== true) {
            if (name == 'parent') {
                return this.parent.data;
            }

            return this.parent.get(name);
        }

        return null;
    },

    add: function(name, value) {
        if (this.data[name]) {
            if (Array.isArray(this.data[name])) {
                this.data[name].push(value);
            } else {
                this.data[name] = [this.data[name], value];
            }
        } else {
            this.set(name, value);
        }
        dmx.requestUpdate(name);
        dmx.requestUpdate(this.name);
        this.updateRelated();
    },

    set: function(name, value) {
        if (typeof name == 'object') {
            for (var prop in name) {
                if (name.hasOwnProperty(prop)) {
                    this.set(prop, name[prop]);
                }
            }
            return;
        }

        if (!dmx.equal(this.data[name], value)) {
            this.data[name] = value;
            dmx.requestUpdate(name);
            dmx.requestUpdate(this.name);
            this.updateRelated();
        }
    },

    del: function(name) {
        delete this.data[name];
        dmx.requestUpdate(name);
        dmx.requestUpdate(this.name);
        this.updateRelated();
    }
});
