window.dmx = window.dmx || Object.create(null);

dmx.__components = Object.create(null);
dmx.__attributes = {
    before: Object.create(null),
    mount: Object.create(null),
    mounted: Object.create(null)
};
dmx.__formatters = {
    boolean: Object.create(null),
    global: Object.create(null),
    string: Object.create(null),
    number: Object.create(null),
    object: Object.create(null),
    array: Object.create(null),
    any: Object.create(null)
};
dmx.__adapters = Object.create(null);
dmx.__actions = Object.create(null);
dmx.__startup = new Set();

// default options
dmx.config = {
    mapping: {
        'form': 'form',
        'button, input[type=button], input[type=submit], input[type=reset]': 'button',
        'input[type=radio]': 'radio',
        'input[type=checkbox]': 'checkbox',
        'input[type=file][multiple]': 'input-file-multiple',
        'input[type=file]': 'input-file',
        //'input[type=number]': 'input-number',
        'input': 'input',
        'textarea': 'textarea',
        'select[multiple]': 'select-multiple',
        'select': 'select',
        '.checkbox-group': 'checkbox-group',
        '.radio-group': 'radio-group'
    }
};

dmx.reIgnoreElement = /^(script|style)$/i;
dmx.rePrefixed = /^dmx-/i;
dmx.reExpression = /\{\{(.+?)\}\}/;
dmx.reExpressionReplace = /\{\{(.+?)\}\}/g;
dmx.reToggleAttribute = /^(checked|selected|disabled|required|hidden|async|autofocus|autoplay|default|defer|multiple|muted|novalidate|open|readonly|reversed|scoped)$/i;
dmx.reDashAlpha = /-([a-z])/g;
dmx.reUppercase = /[A-Z]/g;

// Trigger event on pushState and replaceState
// https://stackoverflow.com/questions/5129386/how-to-detect-when-history-pushstate-and-history-replacestate-are-used/25673911#25673911
(function() {
    const _wr = function(type) {
        const orig = history[type];

        return function() {
            const rv = orig.apply(this, arguments);
            const e = new Event(type.toLowerCase());
            e.arguments = arguments;
            window.dispatchEvent(e);
            return rv;
        };
    };

    history.pushState = _wr('pushState');
    history.replaceState = _wr('replaceState');
})();

dmx.appConnect = function(node, cb) {
    if (dmx.app) {
        return alert('App already running!');
    }

    if (!node) {
        if (cb) cb();
        return;
    }

    history.replaceState({ title: document.title }, '');

    window.onpopstate = function(e) {
        setTimeout(() => {
            if (e.state && e.state.title) {
                document.title = e.state.title;
            }
            dmx.requestUpdate();
        }, 0);
    };

    window.onhashchange = function() {
        dmx.requestUpdate();
    };

    var App = dmx.Component('app');

    dmx.app = new App(node, dmx.global);
    dmx.app.$update();
    if (cb) cb();
};

document.documentElement.style.visibility = 'hidden';

document.addEventListener('DOMContentLoaded', () => {
    Promise.all(dmx.__startup).then(() => {
        var appNode = document.querySelector(':root[dmx-app], [dmx-app], :root[is="dmx-app"], [is="dmx-app"]');

        dmx.appConnect(appNode, function() {
            document.documentElement.style.visibility = '';
            appNode && appNode.removeAttribute('dmx-app');
        });
    });
});

dmx.useHistory = window.history && window.history.pushState;

dmx.extend = function () {
    // Variables
    var extended = {};
    var deep = false;
    var i = 0;
    var length = arguments.length;

    // Check if a deep merge
    if ( Object.prototype.toString.call( arguments[0] ) === '[object Boolean]' ) {
        deep = arguments[0];
        i++;
    }

    // Merge the object into the extended object
    var merge = function (obj) {
        for ( var prop in obj ) {
            // Prototype polution protection
            if (prop == '__proto__') continue;

            if ( Object.prototype.hasOwnProperty.call( obj, prop ) ) {
                // If deep merge and property is an object, merge properties
                if ( deep && Object.prototype.toString.call(obj[prop]) === '[object Object]' ) {
                    extended[prop] = dmx.extend( true, extended[prop], obj[prop] );
                } else {
                    if (obj[prop] != null) {
                        extended[prop] = obj[prop];
                    }
                }
            }
        }
    };

    // Loop through each object and conduct a merge
    for ( ; i < length; i++ ) {
        var obj = arguments[i];
        merge(obj);
    }

    return extended;
};

dmx.noop = function() {};

dmx.isset = function(val) {
    return v !== undefined;
};

dmx.parseDate = function(obj) {
    if (typeof obj == 'string') {
        var d, struct, offset = 0, n = [1,4,5,6,7,10,11];

        if (obj.toLowerCase() == 'now') {
            return new Date();
        }

        if ((struct = /^(\d{4}|[+\-]\d{6})(?:-(\d{2})(?:-(\d{2}))?)?(?:[T ](\d{2}):(\d{2})(?::(\d{2})(?:\.(\d{3}))?)?(?:(Z)|([+\-])(\d{2})(?::(\d{2}))?)?)?$/.exec(obj))) {
            for (var i = 0, k; (k = n[i]); ++i) {
                struct[k] = +struct[k] || 0;
            }

            struct[2] = (+struct[2] || 1) - 1;
            struct[3] = +struct[3] || 1;

            if (struct[8] === undefined) {
                return new Date(struct[1], struct[2], struct[3], struct[4], struct[5], struct[6], struct[7]);
            } else {
                if (struct[8] !== 'Z' && struct[9] !== undefined) {
                    offset = struct[10] * 60 + struct[11];
                    if (struct[9] === '+') offset = 0 - offset;
                }

                return new Date(Date.UTC(struct[1], struct[2], struct[3], struct[4], struct[5] + offset, struct[6], struct[7]));
            }
        } else if ((struct = /^(\d{2}):(\d{2})(?::(\d{2})(?:\.(\d{3}))?)?(?:(Z)|([+\-])(\d{2})(?::(\d{2}))?)?$/.exec(obj))) {
            var d = new Date();
            if (struct[5] === 'Z') {
                d.setUTCHours(+struct[1] || 0);
                d.setUTCMinutes(+struct[2] || 0);
                d.setUTCSeconds(+struct[3] || 0);
                d.setUTCMilliseconds(+struct[4] || 0);
            } else {
                d.setHours(+struct[1] || 0);
                d.setMinutes(+struct[2] || 0);
                d.setSeconds(+struct[3] || 0);
                d.setMilliseconds(+struct[4] || 0);
            }
            return d;
        }

        return new Date(obj);
    } else if (typeof obj == 'number') {
        return new Date(obj * 1000);
    } else {
        return new Date('');
    }
};

dmx.array = function(arr) {
    if (arr == null) return [];
    return Array.prototype.slice.call(arr);
};

dmx.hashCode = function(o) {
    if (o == null) return 0;
    var str = JSON.stringify(o);
    var i, hash = 0;
    for (i = 0; i < str.length; i++) {
        hash = ((hash << 5) - hash) + str.charCodeAt(i);
        hash = hash & hash;
    }
    return Math.abs(hash);
};

dmx.randomizer = function(seed) {
    seed = +seed || 0;
    return function() {
        seed = (seed * 9301 + 49297) % 233280;
        return seed / 233280;
    };
};

dmx.repeatItems = function(repeat) {
    const items = [];

    if (repeat) {
        if (typeof repeat == 'object') {
            if (Array.isArray(repeat)) {
                for (let i = 0, l = repeat.length; i < l; i++) {
                    const item = dmx.clone(repeat[i]);
                    items.push(Object.assign({}, item, {
                        $key: i,
                        $index: i,
                        $value: item
                    }));
                }
            } else {
                let i = 0;
                for (const key in repeat) {
                    if (repeat.hasOwnProperty(key)) {
                        const item = dmx.clone(repeat[key]);
                        items.push(Object.assign({}, item, {
                            $key: key,
                            $index: i,
                            $value: item
                        }));
                        i++;
                    }
                }
            }
        } else if (typeof repeat == 'number') {
            for (let n = 0; n < repeat; n++) {
                items.push({
                    $key: String(n),
                    $index: n,
                    $value: n + 1
                });
            }
        }
    }

    return items;
};

dmx.escapeRegExp = function(val) {
    // https://github.com/benjamingr/RegExp.escape
    return val.replace(/[\\^$*+?.()|[\]{}]/g, '\\$&');
};

dmx.validate = function(node) {
    if (node.tagName == 'FORM') {
        Array.from(node.elements).forEach(node => node.dirty = true);
    }

    return node.checkValidity();
};

dmx.validateReset = function(node) {
    // reset validation?
};

(() => {
    const queue = [];

    window.addEventListener('message', event => {
        if (event.source === window && event.data === 'dmxNextTick' && queue.length) {
            const task = queue.shift();
            task.fn.call(task.context);
        }
    });

    dmx.nextTick = (fn, context) => {
        queue.push({ fn, context });
        window.postMessage('dmxNextTick', '*');
    }
})();

/*
// promises are to fast, need more time before executing
dmx.nextTick = function(fn, context) {
    return Promise.resolve().then(fn.bind(context));
}
*/

dmx.requestUpdate = function() {
    var updateRequested = false;
    var props = new Set();

    return function(prop) {
        props.add(prop || '*');

        if (!updateRequested) {
            updateRequested = true;

            dmx.nextTick(function() {
                updateRequested = false;
                if (dmx.app) {
                    // clone props
                    var idents = new Set(props);
                    // we need to clear props before updated because new requestUpdate can be called inside the $update
                    props.clear();
                    // run update
                    dmx.app.$update(idents);
                }
            });
        }
    };
}();

dmx.debounce = function(fn, delay) {
    let timeout;

    return function() {
        const cb = () => {
            fn.apply(this, arguments);
        };

        if (delay) {
            clearTimeout(timeout);
            timeout = setTimeout(cb, delay);
        } else {
            cancelAnimationFrame(timeout);
            timeout = requestAnimationFrame(cb);
        }
    };
};

dmx.throttle = function(fn, delay) {
    let throttle = false;
    let args;

    return function() {
        args = Array.from(arguments);

        if (!throttle) {
            const cb = () => {
                throttle = false;
                if (args) fn.apply(this, args);
            };

            fn.apply(this, args);

            args = undefined;
            throttle = true;

            if (delay) {
                setTimeout(cb, delay);
            } else {
                requestAnimationFrame(cb);
            }
        }
    }
};

dmx.keyCodes = {
    'bs': 8,
    'tab': 9,
    'enter': 13,
    'esc': 27,
    'space': 32,
    'left': 37,
    'up': 38,
    'right': 39,
    'down': 40,
    'delete': 46,

    'backspace': 8,
    'pause': 19,
    'capslock': 20,
    'escape': 27,
    'pageup': 33,
    'pagedown': 34,
    'end': 35,
    'home': 36,
    'arrowleft': 37,
    'arrowup': 38,
    'arrowright': 39,
    'arrowdown': 40,
    'insert': 45,
    'numlock': 144,
    'scrolllock': 145,
    'semicolon': 186,
    'equal': 187,
    'comma': 188,
    'minus': 189,
    'period': 190,
    'slash': 191,
    'backquote': 192,
    'bracketleft': 219,
    'backslash': 220,
    'bracketright': 221,
    'quote': 222,

    'numpad0': 96,
    'numpad1': 97,
    'numpad2': 98,
    'numpad3': 99,
    'numpad4': 100,
    'numpad5': 101,
    'numpad6': 102,
    'numpad7': 103,
    'numpad8': 104,
    'numpad9': 105,
    'numpadmultiply': 106,
    'numpadadd': 107,
    'numpadsubstract': 109,
    'numpaddivide': 111,

    'f1': 112,
    'f2': 113,
    'f3': 114,
    'f4': 115,
    'f5': 116,
    'f6': 117,
    'f7': 118,
    'f8': 119,
    'f9': 120,
    'f10': 121,
    'f11': 122,
    'f12': 123,

    'digit0': 48,
    'digit1': 49,
    'digit2': 50,
    'digit3': 51,
    'digit4': 52,
    'digit5': 53,
    'digit6': 54,
    'digit7': 55,
    'digit8': 56,
    'digit9': 57,

    'keya': [65, 97],
    'keyb': [66, 98],
    'keyc': [67, 99],
    'keyd': [68, 100],
    'keye': [69, 101],
    'keyf': [70, 102],
    'keyg': [71, 103],
    'keyh': [72, 104],
    'keyi': [73, 105],
    'keyj': [74, 106],
    'keyk': [75, 107],
    'keyl': [76, 108],
    'keym': [77, 109],
    'keyn': [78, 110],
    'keyo': [79, 111],
    'keyp': [80, 112],
    'keyq': [81, 113],
    'keyr': [82, 114],
    'keys': [83, 115],
    'keyt': [84, 116],
    'keyu': [85, 117],
    'keyv': [86, 118],
    'keyw': [87, 119],
    'keyx': [88, 120],
    'keyy': [89, 121],
    'keyz': [90, 122]
};

dmx.eventListener = function(target, eventType, handler, modifiers) {
    let timeout, throttle;
    
    const listener = function(event) {
        if (modifiers.self && event.target !== event.currentTarget) return;
        if (modifiers.ctrl && !event.ctrlKey) return;
        if (modifiers.alt && !event.altKey) return;
        if (modifiers.shift && !event.shiftKey) return;
        if (modifiers.meta && !event.metaKey) return;

        if ((event.originalEvent || event).nsp && !Object.keys(modifiers).includes((event.originalEvent || event).nsp)) {
            return;
        }

        if ((event.originalEvent || event) instanceof MouseEvent) {
            if (modifiers.button != null && event.button != (parseInt(modifiers.button, 10) || 0)) return;
            if (modifiers.button0 && event.button != 0) return;
            if (modifiers.button1 && event.button != 1) return;
            if (modifiers.button2 && event.button != 2) return;
            if (modifiers.button3 && event.button != 3) return;
            if (modifiers.button4 && event.button != 4) return;
        }

        if ((event.originalEvent || event) instanceof KeyboardEvent) {
            var keys = [];

            Object.keys(modifiers).forEach(function(key) {
                var keyVal = parseInt(key, 10);

                if (keyVal) {
                    keys.push(keyVal);
                } else if (dmx.keyCodes[key]) {
                    keys.push(dmx.keyCodes[key]);
                }
            });

            for (var i = 0; i < keys.length; i++) {
                if (Array.isArray(keys[i])) {
                    if (!keys[i].includes(event.which)) return;
                } else if (event.which !== keys[i]) return;
            }
        }

        if (modifiers.stop) event.stopPropagation();
        if (modifiers.prevent) event.preventDefault();
        
        if (event.originalEvent) event = event.originalEvent;

        if (!event.$data) event.$data = {};

        if (event instanceof MouseEvent) {
            event.$data.altKey = event.altKey;
            event.$data.ctrlKey = event.ctrlKey;
            event.$data.metaKey = event.metaKey;
            event.$data.shiftKey = event.shiftKey;
            event.$data.pageX = event.pageX;
            event.$data.pageY = event.pageY;
            event.$data.x = event.x || event.clientX;
            event.$data.y = event.y || event.clientY;
            event.$data.button = event.button;
        }

        if (event instanceof WheelEvent) {
            event.$data.deltaX = event.deltaX;
            event.$data.deltaY = event.deltaY;
            event.$data.deltaZ = event.deltaZ;
            event.$data.deltaMode = event.deltaMode;
        }

        if (window.PointerEvent && event instanceof PointerEvent) {
            event.$data.pointerId = event.pointerId;
            event.$data.width = event.width;
            event.$data.height = event.height;
            event.$data.pressure = event.pressure;
            event.$data.tangentialPressure = event.tangentialPressure;
            event.$data.tiltX = event.tiltX;
            event.$data.tiltY = event.tiltY;
            event.$data.twist = event.twist;
            event.$data.pointerType = event.pointerType;
            event.$data.isPrimary = event.isPrimary;
        }

        if (window.TouchEvent && event instanceof TouchEvent) {
            const touchMap = touch => ({
                identifier: touch.identifier,
                screenX: touch.screenX,
                screenY: touch.screenY,
                clientX: touch.clientX,
                clientY: touch.clientY,
                pageX: touch.pageX,
                pageY: touch.pageY
            });

            event.$data.altKey = event.altKey;
            event.$data.ctrlKey = event.ctrlKey;
            event.$data.metaKey = event.metaKey;
            event.$data.shiftKey = event.shiftKey;
            event.$data.touches = Array.from(event.touches).map(touchMap);
            event.$data.changedTouches = Array.from(event.changedTouches).map(touchMap);
            event.$data.targetTouches = Array.from(event.targetTouches).map(touchMap);
            event.$data.rotation = event.rotation;
            event.$data.scale = event.scale;
        }

        if (event instanceof KeyboardEvent) {
            event.$data.altKey = event.altKey;
            event.$data.ctrlKey = event.ctrlKey;
            event.$data.metaKey = event.metaKey;
            event.$data.shiftKey = event.shiftKey;
            event.$data.location = event.location;
            event.$data.repeat = event.repeat;
            event.$data.code = event.code;
            event.$data.key = event.key;
        }

        if (modifiers.debounce) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                handler.apply(this, arguments);
            }, parseInt(modifiers.debounce, 10) || 0);
        } else if (modifiers.throttle) {
            if (!throttle) {
                throttle = true;
                handler.apply(this, arguments);
                setTimeout(() => {
                    throttle = false
                }, parseInt(modifiers.throttle, 10) || 0);
            }
        } else {
            return handler.apply(this, arguments);
        }
    };

    modifiers = modifiers || {};

    if (window.Dom7 && target.nodeType === 1) {
        Dom7(target)[modifiers.once ? 'once' : 'on'](eventType.replace(/-/g, '.'), listener, !!modifiers.capture);
    } else if (window.jQuery && !modifiers.capture) {
        jQuery(target)[modifiers.once ? 'one' : 'on'](eventType.replace(/-/g, '.'), listener);
    } else {
        target.addEventListener(eventType.replace(/-/g, '.'), listener, {
            capture: !!modifiers.capture,
            once: !!modifiers.once,
            passive: !!modifiers.passive
        });
    }
};

dmx.createClass = function(proto, parentClass) {
    var Cls = function() {
        if (proto.constructor) {
            proto.constructor.apply(this, arguments);
        }
    };

    if (parentClass && parentClass.prototype) {
        Cls.prototype = Object.create(parentClass.prototype);
    }

    Object.assign(Cls.prototype, proto);

    Cls.prototype.constructor = Cls;

    return Cls;
};

dmx.Config = function(config) {
    Object.assign(dmx.config, config);
};

dmx.Component = function(tag, proto) {
    if (proto) {
        var parentClass = dmx.Component(proto.extends) || dmx.BaseComponent;

        proto.initialData = Object.assign({}, parentClass.prototype.initialData, proto.initialData);
        proto.attributes = Object.assign({}, parentClass.prototype.attributes, proto.attributes);
        proto.methods = Object.assign({}, parentClass.prototype.methods, proto.methods);
        proto.events = Object.assign({}, parentClass.prototype.events, proto.events);

        if (!proto.hasOwnProperty('constructor')) {
            proto.constructor = function(node, parent) {
                parentClass.call(this, node, parent);
            };
        }

        proto.type = tag;

        var Component = dmx.createClass(proto, parentClass);
        Component.extends = proto.extends;

        dmx.__components[tag] = Component;
    }

    return dmx.__components[tag];
};

dmx.Attribute = function(name, hook, fn) {
    if (!dmx.__attributes[hook]) {
        dmx.__attributes[hook] = {};
    }
    dmx.__attributes[hook][name] = fn;
};

dmx.Formatters = function(type, o) {
    if (!dmx.__formatters[type]) {
        dmx.__formatters[type] = {};
    }
    for (var name in o) {
        if (o.hasOwnProperty(name)) {
            dmx.__formatters[type][name] = o[name];
        }
    }
};

dmx.Formatter = function(type, name, fn) {
    if (!dmx.__formatters[type]) {
        dmx.__formatters[type] = {};
    }
    dmx.__formatters[type][name] = fn;
};

dmx.Adapter = function(type, name, fn) {
    if (!dmx.__adapters[type]) {
        dmx.__adapters[type] = {};
    }

    if (fn) {
        dmx.__adapters[type][name] = fn;
    }

    return dmx.__adapters[type][name];
};

dmx.Actions = function(actions) {
    for (var name in actions) {
        if (actions.hasOwnProperty(name)) {
            dmx.__actions[name] = actions[name];
        }
    }
}

dmx.Action = function(name, action) {
    dmx.__actions[name] = action;
};

dmx.Startup = function(promise) {
    dmx.__startup.add(promise);
}
